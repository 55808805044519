<template>
  <div class="application">
    <app-bar @open="openHamburger = !openHamburger" app-page="Admin" />
    <side-bar v-if="openHamburger" :background-color="appearance.navigationColor || defaultNavColor">
      <div>
        <div
          v-for="(item, i) in items.filter((item) => item.show === true)"
          :key="i"
          class="sideItemContainer"
        >
          <!-- Main Item -->
          <div
            :class="[
              item.href === activeLink ? 'active-link' : 'non-active-link',
              item.greyOut ? 'text-optimaWhite opacity-50' : 'text-white',
            ]"
            @click.prevent="
              item.children
                ? openChildren(item)
                : $router.push({ name: item.href }).catch(() => {})
            "
            class="sideItem cursor-pointer font-semibold"
          >
            <div class="iconContainer pl-5 pr-3">
              <icon :icon-name="item.icon" size="xsm" class-name="iconSize" />
            </div>
            <div v-tooltip.top="item.text" class="textContainer">
              {{ item.text }}
            </div>
            <div
              v-if="item.children"
              @click.stop="openChildren(item)"
              class="expandIcon"
            >
              <icon
                :icon-name="item.model ? 'chevronDown' : 'chevronRight'"
                :class="item.greyOut ? 'text-optimaWhite' : ''"
              />
            </div>
          </div>

          <!-- Child Items -->
          <ul v-if="item.model" class="childItems">
            <li
              v-for="(child, index) in item.children.filter(
                (child) => child.show === true
              )"
              :key="index"
              :style="
                child.greyOut
                  ? 'color: #F3F6F9; opacity: 0.5'
                  : child.href === activeLink
                  ? 'color: #F15A29'
                  : 'color: white'
              "
              @click.stop="
                child.greyOut
                  ? $router.push({ name: 'ErrorNoAccess' }).catch(() => {})
                  : $router.push({ name: child.href }).catch(() => {})
              "
            >
              <div class="listDotIcon"></div>
              <div v-tooltip.top="child.text" class="textContainer">
                {{ child.text }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </side-bar>

    <side-bar style="width: 70px" v-else :background-color="appearance.navigationColor || defaultNavColor">
      <div>
        <div
          v-for="(item, i) in items.filter((item) => item.show === true)"
          :key="i"
          :class="[
            item.href === activeLink ? 'active-link' : 'non-active-link',
          ]"
          @click.prevent="
            item.children
              ? openChildren(item)
              : $router.push({ name: item.href }).catch(() => {})
          "
          class="text-base flex-wrap sideItem active cursor-pointer flex text-center text-white font-semibold"
        >
          <Menu
            v-if="item.children"
            @click.stop="openChildren(item)"
            top="-150"
            right
            margin="69"
            class="pr-3 pl-5"
            style="margin: auto 0"
          >
            <template v-slot:title>
              <icon :icon-name="item.icon" size="xsm" class-name="iconSize" />
            </template>
            <template v-slot:dropdownTitle>
              <p class="text-left uppercase">
                {{ item.text }}
              </p>
            </template>
            <ul style="width: 200px; padding-left: 30px">
              <li
                v-for="(child, index) in item.children.filter(
                  (child) => child.show === true
                )"
                :key="index"
                style
                class="text-justify mt-4 cursor-pointer"
                :style="
                  child.href === activeLink
                    ? 'color: #F15A29'
                    : 'color: #878E99;'
                "
                @click.stop="$router.push({ name: child.href }).catch(() => {})"
              >
                {{ child.text }}
              </li>
            </ul>
          </Menu>
          <div class="pr-3 pl-5" style="margin: auto 0" v-else>
            <icon :icon-name="item.icon" size="xsm" class-name="iconSize" />
          </div>
        </div>
      </div>
    </side-bar>
    <div
      class="border banner text-center text-lg font-extrabold"
      v-if="windowWidth <= 768"
      :style="`${
        openHamburger
          ? 'left: 220px; width:calc(100vw - 220px)'
          : 'left: 70px; width:calc(100vw - 70px)'
      }`"
    >
      We have detected you are using a device with a small screen to access the
      app. We recommend a device with a larger screen for a better user
      experience
    </div>
    <div
      v-if="showError && !hasSubscriptionExpired()"
      class="border secondBanner w-2/4 text-desire text-left text-lg font-extrabold"
    >
      <span>
        <Icon class="" icon-name="icon-info" size="xsm" />
      </span>
      <span class="flex w-full">
        <span class="font-semibold flex-1 ml-4">{{ timerCount }}</span>
        <span @click="handleCloseTimer" class="cursor-pointer">
          <Icon icon-name="close" class="text-black mt-1" size="xsm" />
        </span>
      </span>
    </div>
    <main
      class="pt-16 screen"
      style="margin-bottom: 70px"
      :style="`${openHamburger ? 'margin-left: 220px' : 'margin-left: 70px'}`"
    >
      <router-view />
    </main>
    <Modal
      v-if="hasSubscriptionExpired() || !$store.state.subscriptionIsActive"
    >
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold my-5 text-left">Subscription Expired</p>
          <p class="mb-6">
            Sorry, your subscription has expired,
            <span>
              {{
                $AuthUser.isRoot
                  ? "Kindly click to manage your subscription."
                  : "Please contact your admin."
              }}
            </span>
          </p>
          <div class="flex mb-4">
            <Button
              background-color="none"
              border="1px solid #F15A29"
              class="text-flame mr-4"
              v-if="$AuthUser.isRoot"
              @click="handleManageSub"
            >
              Manage Subscription
            </Button>
            <Button
              background-color="none"
              class="text-flame"
              v-if="$AuthUser.isEmployee"
              border="1px solid #F15A29"
              @click="$router.push({ name: 'EssDashboard' })"
            >
              Continue to Ess
            </Button>
          </div>
          <div
            @click="$handleLogout"
            class="flex justify-end underline text-darkPurple cursor-pointer"
          >
            Logout
          </div>
        </div>
      </Card>
    </Modal>
    <Modal v-if="showSubModal">
      <Card class="p-5 flex flex-col max-w-3xl">
        <div class>
          <p class="font-bold my-5">
            {{
              `${showDays} left in your ${
                subStatus === "paid" ? "subscription" : "free trial"
              }`
            }}
          </p>
          <p style="white-space: pre-wrap" class="mb-6 text-justify">
            {{
              subStatus === "paid"
                ? `\nYour subscription expires on ${$DATEFORMAT(
                    new Date($store.state.expiryDate),
                    "dd/MM/yyyy"
                  )}
            and will be automatically renewed to enable you to continue to
            access your Cloudenly account.\n\nIf your payment card on file has
            expired and/or is no longer valid, please, update before your
            subscription expires to continue to have access to your Cloudenly
            account. \n\nYour new card will not be charged until your current
            subscription ends and you can cancel your subscription at any time.`
                : `\nYour free trial ends on ${$DATEFORMAT(
                    new Date($store.state.expiryDate),
                    "dd/MM/yyyy"
                  )}.\n\nPlease, upgrade to a paid subscription before your trial ends to continue to have access to
            Cloudenly.\n\nTo upgrade, you need to add a payment card to your account. Your new card will not be charged
            until your current subscription ends and you can cancel your subscription at any time.`
            }}
          </p>
          <div class="flex mb-4">
            <Button
              border="1px solid #F15A29"
              v-if="$AuthUser.isRoot"
              class="text-white mr-4"
              :background="appearance.buttonColor || defaultBTNColor"
              @click="handleManageSub"
            >
              Manage Subscription
            </Button>
            <Button
              background-color="none"
              class="text-flame"
              border="1px solid #F15A29"
              @click="showSubModal = false"
            >
              Close
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
    <!-- <footer
      style="z-index: 50; height: 40px; position: sticky;
    bottom: 0; background: #F1F1F1;
    width: 100vw; margin-top:5rem !important"
      class="flex"
    >
      <div class="text-xs flex-grow">
        {{ `Copyright &copy; ${$DATEFORMAT(new Date(),"YYYY")}, Scelloo . All Right Reserved.` }}
      </div>

      <div class="text-xs">
        Powered by cloudenly Limited.
      </div>
    </footer> -->
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { parseISO, formatDuration, intervalToDuration } from "date-fns";
import SideBar from "@scelloo/cloudenly-ui/src/components/side-bar";
import AppBar from "@/components/appBar/appBar";
import Icon from "@/components/Icon";
import Menu from "@/components/Menu";
import Card from "@/components/Card";
import Button from "@/components/Button";
import Modal from "@/components/Modal";

export default {
  components: {
    SideBar,
    AppBar,
    Icon,
    Button,
    Card,
    Modal,
    Menu,
  },
  name: "Dasboard",
  data() {
    return {
      isSubscriptionActive: true,
      openOptions: false,
      showError: false,
      showSubModal: false,
      windowWidth: window.screen.width,
      openHamburger: true,
      activeLink: this.$router.currentRoute.name,
      timerCount: null,
    };
  },
  computed: {
    items() {
      const itemsData = [
        {
          icon: "dashboard",
          text: "Dashboard",
          greyOut: false,
          href: "AdminDashboard",
          show: true,
        },
        {
          icon: "organisation",
          text: "Organisation",
          model: false,
          greyOut: this.plan === "basic" || this.plan === "standard",
          show: true,
          children: [
            {
              icon: "dot",
              text: "Organisation Structure",
              greyOut: this.plan === "basic" || this.plan === "standard",
              href: "Functions",
              show: true,
            },
            {
              icon: "dot",
              text: "Job Level",
              greyOut: this.plan === "basic" || this.plan === "standard",
              href: "levels",
              show: true,
            },
            {
              icon: "dot",
              text: "Designation",
              greyOut: this.plan === "basic" || this.plan === "standard",
              href: "designation",
              show: true,
            },
            // Look in to this down
            {
              icon: "dot",
              text: "People",
              greyOut: this.plan === "basic" || this.plan === "standard",
              href: "OrganisationPeople",
              show: true,
            },
          ],
        },
        {
          icon: "locationAndTax",
          text: "Location",
          greyOut: false,
          href: "LocationAndTax",
          show: true,
        },
        {
          icon: "employees",
          text: "Employees",
          greyOut: false,
          show: true,
          model: false,
          children: [
            {
              icon: "dot",
              text: "Overview",
              greyOut: false,
              href: "EmployeeOverview",
              show: true,
            },
            {
              icon: "dot",
              text: "Emp. Directory",
              greyOut: false,
              href: "EmployeeDirectory",
              show: true,
            },
            {
              icon: "dot",
              text: "Confirmation",
              greyOut: this.plan === "basic",
              href: "Confirmation",
              show: true,
            },
            {
              icon: "dot",
              text: "Promotions",
              greyOut: this.plan === "basic",
              href: "Promotion",
              show: true,
            },
            {
              icon: "dot",
              text: "Redeployment",
              greyOut: this.plan === "basic",
              href: "Redeployment",
              show: true,
            },
            {
              icon: "dot",
              text: "Delegation Mgt.",
              greyOut: this.plan === "basic",
              href: "DelegationManagement",
              show: true,
            },
            {
              icon: "dot",
              text: "Disciplinary Action",
              greyOut: this.plan === "basic",
              href: "UsersAndGroups",
              show: process.env.NODE_ENV === "development",
            },
            // {
            //   icon: "dot",
            //   text: "Emp. Engagement",
            //   href: "EmployeeEngagements",
            //   show: process.env.NODE_ENV === "development"
            // }
          ],
        },
        {
           icon: "document",
           text: "Documents",
           show: process.env.NODE_ENV === "development",
           href: "OrganisationCompanyDocuments"
        },
        {
          icon: "icon-compensation",
          text: "Compensation",
          model: false,
          greyOut: this.plan === "basic" || this.plan === "standard",
          show: true,
          children: [
            {
              icon: "dot",
              text: "Overview",
              greyOut: this.plan === "basic" || this.plan === "standard",
              href: "CompensationOverview",
              show: true,
            },
            {
              icon: "dot",
              text: "Pay Insight",
              greyOut: this.plan === "basic" || this.plan === "standard",
              href: "CompensationPayInsight",
              show: true,
            },
            {
              icon: "dot",
              text: "Review",
              greyOut: this.plan === "basic" || this.plan === "standard",
              href: "CompensationReview",
              show: true,
            },
            {
              icon: "dot",
              text: "Pay Benchmark",
              greyOut: this.plan === "basic" || this.plan === "standard",
              href: "CompensationPayBenchMark",
              show: process.env.NODE_ENV === "development",
            },
          ],
        },
        {
          icon: "payroll",
          text: "Payroll",
          greyOut: false,
          show: true,
          model: false,
          children: [
            {
              icon: "dot",
              text: "Overview",
              greyOut: false,
              href: "Payroll Dashboard",
              show: true,
            },
            {
              icon: "dot",
              text: "Eligible Employees",
              greyOut: false,
              href: "Employee Payroll",
              show: true,
            },
            {
              icon: "dot",
              text: "Variable Earnings",
              greyOut: this.plan === "basic",
              href: "VariableEarnings",
              show: true,
            },
            {
              icon: "dot",
              text: "Pay Run",
              greyOut: false,
              href: "Pay Runs",
              show: true,
            },
            {
              icon: "dot",
              text: "Pension",
              greyOut: false,
              href: "Pension",
              show: true,
            },
            {
              icon: "dot",
              text: "PAYE",
              greyOut: false,
              href: "PAYE",
              show: true,
            },
            {
              icon: "dot",
              text: "NHF",
              greyOut: false,
              href: "NHF",
              show: true,
            },
            {
              icon: "dot",
              text: "Reports",
              greyOut: false,
              href: "Reports",
              show: process.env.NODE_ENV === "development",
            },
          ],
        },
        {
          icon: "icon-sand-clock",
          text: "Leave Management",
          greyOut: this.plan === "basic",
          show: true,
          href: "LeaveManagement",
        },
        {
          icon: "icon-clockin",
          text: "Time & Attendance",
          greyOut: this.plan === "basic",
          show: process.env.NODE_ENV === "development",
          href: "TimeAttendance",
        },
        {
          icon: "advance_icon",
          text: "Advance & Loans",
          greyOut: this.plan === "basic",
          show: true,
          href: "SalaryAdvance",
        },
        {
          icon: "overtime_icon",
          text: "Overtime",
          greyOut: this.plan === "basic",
          show: true,
          href: "Overtime",
        },
        {
          icon: "performance",
          text: "Performance",
          greyOut: this.plan === "basic" || this.plan === "standard",
          show: true,
          model: false,
          children: [
            // {
            //   icon: require("./../../../assets/dashboard/dot.svg"),
            //   text: "Dashboard",
            //   href: "PerformanceSetting",
            // },
            {
              icon: "dot",
              text: "Organisational Goals",
              greyOut: this.plan === "basic" || this.plan === "standard",
              href: "OrganisationlGoal",
              show: true,
            },
            {
              icon: "dot",
              text: "Perf. Agreements",
              greyOut: this.plan === "basic" || this.plan === "standard",
              href: "PerformanceAgreement",
              show: true,
            },
            {
              icon: "dot",
              text: "Perf. Appraisals",
              greyOut: this.plan === "basic" || this.plan === "standard",
              href: "PerformanceAppraisals",
              show: true,
            },
            {
              icon: "dot",
              text: "Perf. Moderation",
              greyOut: this.plan === "basic" || this.plan === "standard",
              href: "PerformanceModeration",
              show: process.env.NODE_ENV === "development",
            },
            {
              icon: "dot",
              text: "Perf. Improvement",
              greyOut: this.plan === "basic" || this.plan === "standard",
              href: "PerformanceImprovment",
              show: process.env.NODE_ENV === "development",
            },
          ],
        },
        {
          icon: "icon-approval",
          text: "Approval",
          greyOut: false,
          model: false,
          show: true,
          children: [
            {
              icon: "dot",
              text: "My Requests",
              greyOut: false,
              href: "MyRequests",
              show: true,
            },
            {
              icon: "dot",
              text: "My Approvals",
              greyOut: false,
              href: "MyApprovals",
              show: true,
            },
          ],
        },
        {
          icon: "icon-chart",
          text: "Analytics",
          greyOut: false,
          show: true,
          model: false,
          children: [
            {
              icon: "dot",
              text: "Organisation",
              greyOut: this.plan === "basic" || this.plan === "standard",
              href: "OrganisationAnalytics",
              show: true,
            },
            {
              icon: "dot",
              text: "Employee",
              greyOut: false,
              href: "EmployeeAnalytics",
              show: true,
            },
            {
              icon: "dot",
              text: "Compensation",
              greyOut: this.plan === "basic" || this.plan === "standard",
              href: "CompensationAnalytics",
              show: process.env.NODE_ENV === "development",
            },
            {
              icon: "dot",
              text: "Payroll",
              greyOut: false,
              href: "PayrollAnalytics",
              show: true,
            },
          ],
        },
      ];
      return itemsData.map((item) => Vue.observable(item));
    },
    ...mapState({
      subscriptionDetails: (state) => state.subscription,
      defaultNavColor: (state) => state.orgAppearance.defaultSideNavColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
    plan() {
      const result = this.$store.state.subscription
        ? this.$store.state.subscription.plan
        : null;
      return result;
    },
    hasSubscriptionExpired() {
      return this.$store.getters.hasSubscriptionExpired;
    },
    subStatus() {
      return this.$store.state.subscriptionStatus;
    },
    showDays() {
      const duration = intervalToDuration({
        start: new Date(),
        end: parseISO(this.$store.state.expiryDate),
      });
      return formatDuration(duration, { format: ["days"], zero: true });
    },
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    })
  },
  methods: {
    truncate(str, length = 15) {
      if (str.length > length) {
        return `${str.substring(0, length)}...`;
      }
      return str;
    },
    onResize() {
      this.windowWidth = window.screen.width;
    },
    handleManageSub() {
      return window.location.replace(
        `${process.env.VUE_APP_CLOUDENLY}subscriptions/management`
      );
    },
    openChildren(item) {
      this.items.forEach((element) => {
        const hasChildren = Object.prototype.hasOwnProperty.call(
          element,
          "children"
        );
        if (hasChildren) {
          const dropdownElement = element;
          if (item.text === dropdownElement.text) {
            dropdownElement.model = !dropdownElement.model;
            return;
          }
          dropdownElement.model = false;
        }
      });
    },
    handleCloseTimer() {
      this.showError = false;
    },
    showSubExpiresIn7DaysModal() {
      if (
        !this.hasSubscriptionExpired() &&
        this.$AuthUser.isRoot &&
        this.$SubscriptionExpiresIn7Days()
      ) {
        this.showSubModal = true;
      } else {
        this.showSubModal = false;
      }
    },

    timerCounter() {
      this.$store.commit("SET_APP_SUBSCRIPTION");
      if (!this.hasSubscriptionExpired()) {
        setTimeout(() => {
          const duration = intervalToDuration({
            start: new Date(),
            end: parseISO(this.$store.state.expiryDate),
          });
          this.timerCount = `Your Subscription will expire in ${formatDuration(
            duration,
            {
              delimiter: ", ",
            }
          )}`;
          this.timerCounter();
        }, 1000);
      }
    },
  },

  watch: {
    $route(to) {
      const link = to.name.toString();
      this.activeLink = link;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  beforeMount () {
    const hasRefreshed = localStorage.getItem('hasRefreshed')
    if (hasRefreshed === 'false') {
      localStorage.setItem('hasRefreshed', 'true')
      window.location.reload()
    }
  },
  created() {
    if (this.$SubscriptionExpiresIn3Days()) {
      this.showError = true;
      this.timerCounter();
    }
  },

  mounted() {
    this.showSubExpiresIn7DaysModal();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    if (this.$IsTrial()) {
      this.isSubscriptionActive = true;
    } else {
      this.$store.dispatch("getSubscription").then(() => {
        this.isSubscriptionActive = this.subscriptionDetails.isActive;
      });
    }
  },
};
</script>

<style>
.application {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.secondBanner {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 6px 12px;
  position: absolute;
  margin-left: -25%;
  top: 74px;
  left: 58%;
  background-color: #fff5f5;
}

.banner {
  display: block;
  position: absolute;
  top: 65px;
  animation: blinker 2s step-start infinite;
  background-color: rgb(240, 229, 226);
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.sideItemContainer {
  display: flex;
  flex-direction: column;
}

.textContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  padding-right: 15px;
}

.expandIcon {
  margin: 0px 15px 0px auto;
}
/* Styles for Child Items */

.childItems li {
  display: flex;
  align-items: center;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  cursor: pointer;
  text-overflow: ellipsis;
  margin-top: 16px;
  padding-left: 18px; /* Adjust padding for the list dot */
  /* Other styles... */
}
.listDotIcon {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ffffff; /* Customize color */
  margin-right: 10px; /* Adjust margin between the icon and text */
}

.sideBar {
  position: relative;
}

.iconSize {
  width: 20px;
  height: 20px;
}

.active-link {
  background-color: #f15a29 !important;
  min-height: 40px;
}

main {
  flex: 1;
  max-height: 100%;
}

ul {
  list-style-type: disc;
  padding-left: 40px;
  margin: 0;
}

ol {
  list-style-type: decimal;
  padding-left: 14px;
  margin: 0;
}

.non-active-link:hover {
  background-color: #b9b5b52d;
}

.sideItem {
  display: flex;
  align-items: center;
  border-radius: 5px;
  min-height: 40px;
  margin: 12px 5px 0px 5px;
}

.active:active {
  background-color: #f15a29 !important;
}
</style>
